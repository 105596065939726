
import { inject, computed, onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
    name: 'How',

    setup() {
        const howStore: any = inject('howStore')
        const router: any = useRouter()

        const menuOption = ref(0)

        const authStore: any = inject('authState')

        const loadData = () => {
            howStore.getHowWorkData()
        }
        const goDashboard = () => {
            router.push({ name: 'tests.index' })
        }

        const menuScroll = (opt: any) => {
            menuOption.value = opt
            switch (opt) {
                case 0: {
                    const element0: any = document.getElementById(
                        'howItWorks_section_0'
                    )
                    element0.scrollIntoView({
                        block: 'start',
                        behavior: 'smooth',
                    })
                    break
                }

                case 1: {
                    const element1: any = document.getElementById(
                        'howItWorks_section_1'
                    )
                    element1.scrollIntoView({
                        block: 'start',
                        behavior: 'smooth',
                    })
                    break
                }

                case 2: {
                    const element2: any = document.getElementById(
                        'howItWorks_section_2'
                    )
                    element2.scrollIntoView({
                        block: 'start',
                        behavior: 'smooth',
                    })
                    break
                }

                case 3: {
                    const element3: any = document.getElementById(
                        'howItWorks_section_3'
                    )
                    element3.scrollIntoView({
                        block: 'start',
                        behavior: 'smooth',
                    })
                    break
                }

                default:
                    break
            }
        }

        onBeforeMount(() => {
            loadData()
        })

        return {
            howWorkData: computed(() => howStore.state.howWorkData),
            goDashboard,
            menuOption,
            menuScroll,
        }
    },
}
